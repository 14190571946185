<template>
  <div class="container" v-title :data-title="$t('i18n.pendingIssues')">
    <div id="outer-title">{{ $t("i18n.pendingIssues") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "ResultBoard",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "billNumber",
          label: "orderNumber",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "status",
          label: "currentStatus",
          align: "center",
          formatter: (row) => {
            let current = proxy.$config.billStatus.filter((item) => {
              return item.id == row.status;
            });
            if (current.length === 0) {
              return `<span>${row.status}</span>`;
            } else {
              return `<a style="color: ${current[0].color}">
                ${t("i18n." + current[0].locale)}
              </a>`;
            }
          },
          method: (row) => {
            enterProcessPage(row.id);
          },
        },
        {
          prop: "result",
          label: "pendingIssues",
          align: "center",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "maintenanceName",
          label: "handler",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "inspectStatus",
          label: "inspectStatus",
          align: "center",
          search: true,
          type: "select",
          props: { label: "locale", value: "value" },
          data: [
            {
              locale: "noInspect",
              value: "1",
            },
            {
              locale: "alreadyInspect",
              value: "2",
            },
          ],
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (row.inspectResult === null) {
              return `<span style="color: OrangeRed">
                ${t("i18n.noInspect")}
              </span>`;
            } else {
              return `<span style="color: black">
                ${t("i18n.alreadyInspect")}
              </span>`;
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
      editId: "",
    });

    const init = async (params) => {
      state.loading = true;
      setData(params);
      const { data } = await proxy.$api.maintenance.getIssuesData(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const setData = (data) => {
      let params = JSON.parse(JSON.stringify(data));
      delete params.page;
      let arr = ["pageNumber", "pageSize"];
      for (var key in params) {
        if (arr.indexOf(key) == -1 && key.indexOf("search_") == -1) {
          params["search_" + key + "_LIKE"] = params[key];
          delete params[key];
        }
      }
      return params;
    };

    const enterProcessPage = (id) => {
      // 进入工单过程页面
      let routeData = router.resolve({
        path: "/maintenance/workBillwbStatus",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    };

    return {
      ...toRefs(state),
      enterProcessPage,
      init,
    };
  },
};
</script>
